<template>
  <v-container class="my-auto">
      <div class="d-flex">
          <v-text-field
                  v-model="url"
                  :error-messages="current_error"
                  label="URL"
                  prepend-icon="mdi-link-variant"
                  variant="outlined"
          ></v-text-field>
          <v-btn
                  @click="createUrl"
                  :disabled="!isUrl(url)"
                  color="blue"
                  append-icon="mdi-plus"
                  outlined
                  text="get link"
                  :loading="loading"
                  class="ml-3 mt-2"
          ></v-btn>
      </div>
      <div class="d-flex">
          <v-text-field
              v-model="password"
              @click:append="show_password = !show_password"
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_password ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-key-variant"
              variant="outlined"
              class="mr-3"
          ></v-text-field>
          <div>
              <v-checkbox-btn
                  v-if="!is_temp_link"
                  v-model="is_temp_link"
                  label="Temporary link"
                  color="blue"
              ></v-checkbox-btn>
          </div>
          <v-text-field
              v-if="is_temp_link"
              v-model="timout"
              persistent-hint
              hint="Default 1 week"
              type="date"
              label="URL"
              variant="outlined"
          ></v-text-field>
      </div>
      <div class="d-flex" v-if="current_link">
          <div class="mx-auto">
              <v-btn
                  class="text-lowercase"
                  variant="text"
                  color="blue"
                  append-icon="mdi-open-in-new"
              >
                  <a
                      :href="current_link"
                      target="_blank"
                  >
                      {{ current_link }}
                  </a>
              </v-btn>
              <v-icon
                  @click="copy(current_link)"
                  icon="mdi-content-paste"
                  color="blue"
                  class="my-auto"
              ></v-icon>
              <v-snackbar
                  v-model="snackbar"
                  location="bottom"
              >
                  The link is copied.
                  <template v-slot:actions>
                      <v-btn @click="snackbar=false">Close</v-btn>
                  </template>
              </v-snackbar>
          </div>
      </div>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import {mapActions, mapMutations, mapState} from "vuex";
import router from "@/router";

export default defineComponent({
  name: 'HomeView',
    data: () => ({
        loading: false,
        url: "",
        password: "",
        show_password: false,
        is_temp_link: false,
        timout: null,
        isUrl: v => /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/[^\s]*)?$/.test(v),
        snackbar: false
    }),
    computed: {
      ...mapState(["current_data", "current_error"]),
        current_link() {
          if (this.current_data?.code) {
              return `https://link.nexford.fr/${this.current_data.code}`
          }
          return ""
        }
    },
    methods: {
      ...mapMutations(["set_current_error"]),
      ...mapActions(["add_link"]),
        async createUrl() {
            try {
                this.set_current_error([])
                this.loading = true
                const url = new URL(this.url)
                const timout = this.timout
                if (timout) {
                    const date = new Date(timout).getTime()
                    if (date < Date.now()) throw new Error("Bad date")
                }
                const link = {
                    url: url,
                    timout: timout,
                    password: this.password
                }
                await this.add_link(link)
            } catch (e) {
                console.error(e)
                this.set_current_error("link creation error")
            }
            this.loading = false;
        },
        copy(url) {
            const tempTextarea = document.createElement("textarea");
            tempTextarea.value = url;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
            document.execCommand("copy");
            document.body.removeChild(tempTextarea);
            this.snackbar = true;
            setTimeout(() => {
                this.snackbar = false;
            }, 3000)
        }
    },
    mounted() {
        if (new URL(window.location).searchParams.has("bad_url")) {
            this.set_current_error("This code does not exist.")
            router.push("/")
        }
        try {
            const date = new Date(Date.now()+(7 * 24 * 60 * 60 * 1000))
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            this.timout = year + '-' + month + '-' + day;
        } catch (e) {
            console.error("date creation error")
        }
    }
});
</script>
