import myAxios from "@/services/axios.service";

export default {
    add_link(link) {
        return myAxios.post("/", {
            ...link
        });
    },
    show_link(link) {
        return myAxios.get("/show_link", {
            params: link
        });
    },
    edit_link(link) {
        return myAxios.put("/edit_link", {
            ...link
        });
    },
    delete_link(link) {
        return myAxios.delete("/delete_link", {
            params: link
        });
    }
}