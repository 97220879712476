<template>
    <div>
        <v-alert
                @click:close="set_current_error_edit(null)"
                :model-value="!!current_error_edit"
                :text="current_error_edit"
                type="error"
                icon="mdi-alert-circle"
                closable
        ></v-alert>
        <div v-if="current_link">
            <div>Link :
                <a target="_blank" :href="current_link?.url">{{ current_link?.url }}</a>
            </div>
            <div>Code link :
                <a target="_blank" :href="`https://link.nexford.fr/${current_link?.code}`">https://link.nexford.fr/{{ current_link?.code }}</a>
            </div>
            <div>Code : {{ current_link?.code }}</div>
            <div>Creation date : {{ get_date(current_link?.code) }}</div>
            <div v-if="current_link?.timout" class="text-red">
                Deletion date : {{ get_date(current_link?.timout) }}
            </div>
            <div
                    v-for="(redirection_date, index) in current_link?.redirection_dates"
                    :key="index"
            >
                <div>{{index+1}} : {{ get_date(redirection_date) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: "DisplayLinkComponent",
    data: () => ({
        dateFormatter: new Intl.DateTimeFormat(navigator.language || 'en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }),
    }),
    computed: {
        ...mapState(["current_link", "current_error_edit"])
    },
    methods: {
        ...mapMutations(["set_current_error_edit"]),
        get_date(number) {
            try {
                return this.dateFormatter.format(new Date(number));
            } catch (e) {
                console.error("Bad format date")
            }
            return "No date";
        }
    }
}
</script>

<style scoped>

</style>