<template>
    <v-app-bar>
        <v-app-bar-title text="Link redirection"/>
        <v-btn
            to="/"
            icon="mdi-home"
            text="Home"
            color="blue"
        ></v-btn>
        <v-btn
            to="/edit"
            icon="mdi-pencil"
            text="Edit"
            color="green"
        ></v-btn>
    </v-app-bar>
</template>

<script>
export default {
    name: "NavComponent"
}
</script>

<style scoped>

</style>