<template>
  <v-app>
    <NavComonent></NavComonent>
    <v-main class="d-flex">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import NavComonent from "@/components/NavComponent.vue";

export default {
  name: 'App',
    components: {NavComonent},

  data: () => ({
    //
  }),
}
</script>