import axios from "axios";


const myAxios = axios.create({
    baseURL: process.env.NODE_ENV === "production" ? "https://link.nexford.fr/" : "http://localhost:3000/"
})


myAxios.interceptors.request.use(
    config => {
        return {...config, headers: {}}
    },
    error => {
        return Promise.reject(error)
    }
)

// myAxios.interceptors.response.use(
//     response => {
//         return response
//     },
//     error => {
//         return error
//     }
// )


export default myAxios