import { createStore } from 'vuex'
import requestService from "@/services/request.service";

export default createStore({
  state: {
    current_data: null,
    current_error: [],
    current_link: null,
    current_error_edit: null
  },
  getters: {
  },
  mutations: {
    set_current_data: (state, data) => {
      state.current_data = data;
    },
    set_current_error: (state, error) => {
      state.current_error = error;
    },
    set_current_link: (state, link) => {
      state.current_error_edit = null;
      state.current_link = link;
    },
    set_current_error_edit: (state, error) => {
      // console.error(error?.response?.data)
      error = error?.response?.data?.error
      state.current_error_edit = error
      if (error) state.current_link = null;
    }
  },
  actions: {
    async add_link({commit}, link) {
      try {
        const res = await requestService.add_link(link)
        await commit("set_current_data", res.data)
      } catch (e) {
        // console.error(e)
        await commit("set_current_error_edit", e)
      }
    },
    async show_link({commit}, link) {
      try {
        await commit("set_current_error_edit", null)
        const res = await requestService.show_link(link)
        await commit("set_current_link", res.data)
      } catch (e) {
        await commit("set_current_error_edit", e)
      }
    },
    async edit_link({commit}, link) {
      try {
        await commit("set_current_error_edit", null)
        const res = await requestService.edit_link(link)
        await commit("set_current_link", res.data)
      } catch (e) {
        await commit("set_current_error_edit", e)
      }
    },
    async delete_link({commit}, link) {
      try {
        await commit("set_current_error_edit", null)
        const res = await requestService.delete_link(link)
        await commit("set_current_link", res.data)
      } catch (e) {
        await commit("set_current_error_edit", e)
      }
    }
  },
  modules: {
  }
})
