<template>
    <v-container class="my-auto">
        <div class="d-flex">
            <v-text-field
                v-model="url"
                label="New URL"
                prepend-icon="mdi-link-variant"
                variant="outlined"
            ></v-text-field>
            <v-btn
                @click="editUrl"
                :disabled="!isCode(code) || !isUrl(url) || loading"
                color="green"
                append-icon="mdi-pencil"
                outlined
                text="edit link"
                :loading="loading"
                class="ml-3 mt-2"
            ></v-btn>
        </div>
        <div class="d-flex">
            <v-text-field
                    v-model="code"
                    label="Code"
                    prepend-icon="mdi-numeric"
                    variant="outlined"
            ></v-text-field>
            <v-btn
                @click="showUrl"
                :disabled="!isCode(code) || loading"
                color="blue"
                append-icon="mdi-eye"
                outlined
                text="show link"
                :loading="loading"
                class="ml-3 mt-2"
            ></v-btn>
        </div>
        <div class="d-flex">
            <v-text-field
                    v-model="password"
                    @click:append="show_password = !show_password"
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    label="Password"
                    prepend-icon="mdi-key-variant"
                    variant="outlined"
            ></v-text-field>
            <v-btn
                    @click="deleteUrl"
                    :disabled="!isCode(code) || !password || loading"
                    color="red"
                    append-icon="mdi-delete"
                    outlined
                    text="delete link"
                    :loading="loading"
                    class="ml-3 mt-2"
            ></v-btn>
        </div>
        <DisplayLinkComponent></DisplayLinkComponent>
    </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DisplayLinkComponent from "@/components/DisplayLinkComponent.vue";

export default {
    name: "EditLinkComponent",
    components: {DisplayLinkComponent},
    data: () => ({
        loading: false,
        code: "",
        url: "",
        password: "",
        show_password: false,
        isUrl: v => /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/[^\s]*)?$/.test(v),
        isCode: v => /^(https:\/\/link\.nexford\.fr\/)?\d+$/.test(v)
    }),
    computed: {
        ...mapState(["current_link", "current_error_edit"])
    },
    methods: {
        ...mapActions(["show_link", "edit_link", "delete_link"]),
        showUrl() {
            const code = this.code.split("https://link.nexford.fr/").at(-1)
            this.show_link({
                code: code,
                password: this.password
            })
        },
        editUrl() {
            const code = this.code.split("https://link.nexford.fr/").at(-1)
            this.edit_link({
                code: code,
                url: this.url,
                password: this.password
            })
        },
        deleteUrl() {
            const code = this.code.split("https://link.nexford.fr/").at(-1)
            this.delete_link({
                code: code,
                password: this.password
            })
        }
    }
}
</script>

<style scoped>

</style>